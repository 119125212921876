import React, { useEffect } from "react"
import PreHeader from "../components/preHeader"
import HeaderDrak from "../components/headerDrak"
import Footer from "../components/footer"
import { Container, Row, Col } from "react-bootstrap"
import backBtn from "../images/arrow-grren-outline.svg"
import "../styles/main.scss"

// import for translate
import {
  AUTH_TOKEN_KEY,
  COLOR_GREEN,
  COLOR_RED,
  DOMAIN_API,
  TYPE_ACTU,
  TYPE_ADVANTAGE,
  TYPE_ALL,
  URL_GET_USER_FULL,
  URL_GETALL_ADVANTAGES,
  URL_GETALL_ADVICEES, URL_GETALL_FOLDERS, URL_GETALL_NEWS, URL_GETALL_VIDEOS, URL_GETALL,
  URL_IS_AUTH, translate, URL_GET_MAINPARTNERSUPER_DATA, URL_GETALL_PARTNER_SUPER_FILTERED, getUserLanguage, NL, FR
} from "../utils"
import TagManager from "react-gtm-module"

const PrivacyTerms = () => {
  useEffect(() => {
    const userLanguage = getUserLanguage()
    if (userLanguage === FR) window.location.href = '/privacy-terms-fr'
  },[])

  return (
    <div>
      <PreHeader />
      <div className="privacyTerms">
        <HeaderDrak />
        <div className="pageContent">
          <Container>
            <Row className="justify-content-center align-items-lg-end">
              <Col lg="8" className="order-lg-2">
                <h1 className="h0-title yellow-privacy">
                <span className="d-block">
                Privacybeleid</span>
                </h1>

                {/* paragraphe one start */}
                <h2 className="h2-title yellow-privacy">1. Algemeen</h2>
                <p className="yellow-privacy">
                  Wij zijn van mening dat privacybescherming van het allergrootste belang is.                </p>
                <p className="yellow-privacy">
                  Wij willen u zo veel mogelijk informeren, u voorzien van informatie over de gegevens die we verzamelen, het doel dat we nastreven, de bewaartermijn, de privacy-rechten die u hebt en de manier waarop u deze rechten kunt uitoefenen.                </p>
                {/* paragraphe one end */}

                {/* paragraphe two start */}
                <h2 className="h2-title  yellow-privacy">2. Verantwoordelijk voor verwerking</h2>
                <p className="yellow-privacy">
                  IN TEMPORE bv (handelend onder de handelsnaam ENTER-BUSINESS), met maatschappelijke zetel gevestigd te Kersbeeklaan 306, 1180 Brussel en ingeschreven bij de ECB onder nummer BE 0477.292.452 (hierna “ENTER-BUSINESS”), is de verantwoordelijke voor de behandeling van uw persoonlijke gegevens.                </p>
                <p className="yellow-privacy">
                  Dit betekent dat ENTER-BUSINESS de doelen en middelen definieert voor het verwerken van uw persoonlijke gegevens.
                </p>
                {/* paragraphe two end */}

                {/* paragraphe three start */}
                <h2 className="h2-title yellow-privacy">3. Welke gegevens bezitten wij over u?</h2>
                <p className="yellow-privacy">
                  ENTER-BUSINESS beschikt niet over dezelfde gegevens voor alle personen. Dat hangt af van de gegevens die u of onze partners ons hebben gegeven. U vindt hier een opsomming van de gegevens die ENTER-BUSINESS zou kunnen bezitten:                </p>
                <h4 className="h2-title yellow-privacy">
                  DE PERSOONSGEGEVENS DIE U MET ONS DEELT                    </h4>
                <p className="yellow-privacy">
                  Wij verwerken de persoonlijke gegevens die u spontaan meedeelt. Deze communicatie kan telefonisch worden gedaan (bijvoorbeeld wanneer u de klantendienst belt om een vraag te stellen), schriftelijk (bijvoorbeeld wanneer u een bestelling voltooit om een pakket te reserveren), elektronisch (bijvoorbeeld wanneer u uw registratieformulier invult op de ENTER-BUSINESS sites) of wanneer u ons een sms of een e-mail stuurt om u te registreren voor een onlinewedstrijd of om een ENTER-BUSINESS-app te downloaden.</p>
                <p className="yellow-privacy"><span className="yellow-privacy">
                Het gaat over de volgende gegevens:</span></p>
                <ul className="yellow-privacy">
                  <li>Naam</li>
                  <li>Voornaam</li>
                  <li>Geslacht</li>
                  <li>Taal</li>
                  <li>Leeftijd (of geboortedatum, of veronderstelde gemiddelde leeftijd)</li>
                  <li>Postadres</li>
                  <li>Telefoonnummer</li>
                  <li>Gsm-nummer</li>
                  <li>E-mailadres</li>
                  <li>Bedrijfsnaam</li>
                  <li>Postadres van uw bedrijf</li>
                  <li>Type activiteit van uw bedrijf</li>
                  <li>Uw functie</li>
                  <li>Internetcentra</li>

                </ul>


                <h4 className="h2-title yellow-privacy">PERSOONSGEGEVENS VERKREGEN DOOR DERDEN</h4>
                <p className="yellow-privacy">
                  We kunnen uw gegevens ontvangen van bedrijven die actief zijn in de bedrijfs- of ondernemerschapssector of gespecialiseerde ‘big data’ bedrijven, zoals bijvoorbeeld socio-demografische gegevens en informatie over uzelf om onze diensten beter aan te passen aan uw behoeften.
                </p>
                <p className="yellow-privacy">
                  Vervolgens kunnen we van de Vzw DNCM periodieke overzichten ontvangen van de mensen op de lijst 'Bel me niet meer'.
                  <p className="yellow-privacy">Het bedrijf dat ons uw gegevens verstrekt, kan ons ook vertellen wanneer u voor het laatst contact met hen hebt gehad (omdat u een product of dienst hebt gekocht of omdat u bijvoorbeeld deelnam aan een wedstrijd). Deze informatie stelt ons in staat om te verifiëren dat uw gegevens correct en actueel zijn (als u zojuist uw krant thuis hebt ontvangen, is de kans groot dat u op dit adres woont).</p>
                </p>


                <h4 className="h2-title yellow-privacy">PERSOONSGEGEVENS VERKREGEN DOOR ENQUÊTES</h4>
                <p className="yellow-privacy">
                  Het bedrijf dat ons uw gegevens verstrekt, kan ons ook vertellen wanneer u voor het laatst contact met hen hebt gehad (omdat u een product of dienst hebt gekocht of omdat u bijvoorbeeld deelnam aan een wedstrijd). Deze informatie stelt ons in staat om te verifiëren dat uw gegevens correct en actueel zijn (als u zojuist uw krant thuis hebt ontvangen, is de kans groot dat u op dit adres woont).
                </p>
                <h4 className="h2-title yellow-privacy">
                  LOCATIEGEGEVENS VERKREGEN DOOR FREQUENTE LOCATIES
                </h4>
                <p className="yellow-privacy">
                  In sommige gevallen kunnen we de bedrijven die u vaak bezoekt identificeren, zoals kantoren, beurzen of vergaderzalen. Het verzamelen van deze gegevens helpt ons om de kwaliteit van ons distributienetwerk voor ENTER-BUSINESSkaarten te evalueren.
                </p>


                <h4 className="h2-title yellow-privacy">ANDERE GEGEVENS</h4>
                <p className="yellow-privacy">
                  Cookies of andere soortgelijke technieken: wanneer u onze website www.enterbusiness.com bezoekt, kunnen wij informatie verzamelen uit onze cookies.
                </p>
                <p className="yellow-privacy">
                  We kunnen ook cookies plaatsen op e-mails die we u regelmatig toezenden in overeenstemming met onze verplichtingen. Deze verzamelde informatie helpt ons om u specifieke producten of diensten aan te bieden die u mogelijk interesseren en om uw interesse in de inhoud van verzonden e-mails te meten.
                </p>
                <p className="yellow-privacy">
                  Waar mogelijk houden we ook de data bij waarop al deze informatie aan ons wordt meegedeeld of wanneer er wijzigingen in zijn aangebracht.    </p>
                {/* paragraphe three ends */}



                {/* paragraphe four starts */}
                <h2 className="h2-title yellow-privacy">4. Hoe verzamelen wij gegevens?</h2>
                <p className="yellow-privacy">
                  Hier is een overzicht van de meest gestelde vragen.
                </p>

                <h4 className="h2-title yellow-privacy">HOE VERZAMELT ENTER-BUSINESS GEGEVENS OVER U?</h4>
                <p className="yellow-privacy">
                  ENTER-BUSINESS verzamelt gegevens van u op verschillende manieren waarop u zich vrijwillig kunt registreren om zo kortingen, geschenkpakketten of informatiegidsen te verkrijgen, deel te nemen aan wedstrijden of gebruik te maken van promoties.
                </p>
                <p className="yellow-privacy">
                  Deze registratie kan telefonisch worden gedaan (bijvoorbeeld wanneer u de klantendienst belt om een vraag te stellen), schriftelijk (bijvoorbeeld wanneer u een bestelling voltooit om een pakket te reserveren), elektronisch (bijvoorbeeld wanneer u uw registratieformulier invult op de websites ENTER-BUSINESS.COM) of wanneer u ons een sms of een e-mail stuurt om u te registreren voor een onlinewedstrijd of om een ENTER-BUSINESS-app te downloaden.
                </p>
                <p className="yellow-privacy">
                  ENTER-BUSINESS is ook partnerschappen aangegaan met commerciële bedrijven die hun de gegevens van hun klanten verstrekken.
                </p>
                <p className="yellow-privacy">
                  De gegevens kunnen ook worden aangevuld met een aantal statistische gegevens, zoals die van het Nationaal Instituut voor de Statistiek, bijvoorbeeld het gemiddelde inkomen van de buurt waar mensen wonen, het percentage tuinen, landeigenaren, enz.
                </p>
                <p className="yellow-privacy">
                  Al deze gegevens worden verzameld om ons "consumenten"-bestand te vormen dat op de markt wordt gebracht.
                </p>


                <h4 className="h2-title yellow-privacy">KUNNEN WE UW GEGEVENS INVULLEN MET GEGEVENS DIE WE AFLEIDEN OF VERMOEDEN?</h4>
                <p className="yellow-privacy">
                  Ja, we kunnen uw gegevens aanvullen met afgeleide of veronderstelde gegevens. Dus als u in een eengezinswoning woont en op hetzelfde adres, hebben we ook de gegevens van een andere persoon dan zullen we bijgevolg afleiden dat deze persoon deel uitmaakt van hetzelfde huishouden als u. Dit betekent niet dat we uitgaan van een familierelatie tussen jullie beiden, of dat we uw burgerlijke staat kennen. In werkelijkheid is deze informatie interessant vanuit een marketingperspectief. huishouden wordt verzonden.
                </p>


                <h4 className="h2-title yellow-privacy">MAKEN WE PROFIELEN AAN OP ENTER-BUSINESS UIT DE GEGEVENS DIE WE VERZAMELEN?</h4>
                <p className="yellow-privacy">
                  Ja, we kunnen marketingprofielen opstellen. Concreet betekent dit dat we u in “marketingsegmenten” kunnen plaatsen, bijvoorbeeld “bedrijf in de horecasector”, “ondernemer met meerdere bedrijven”, “mensen die in gemeente X wonen” ...
                </p>
                <p className="yellow-privacy">
                  Deze indeling betekent dat wij uw gegevens zullen verkopen aan bedrijven die producten of diensten verkopen waarvan we denken dat de meeste u interesseren. Omgekeerd zorgt het er ook voor dat we ze niet verkopen aan bedrijven die producten of diensten aanbieden die u waarschijnlijk niet zouden interesseren.
                </p>
                <p className="yellow-privacy">
                  De criteria kunnen puur geografisch zijn, dus een bedrijf kan ons een selectie van mensen vragen die binnen een straal van 10 kilometer van hun winkel wonen, of in gemeente X wonen. Meer in het algemeen, als we aannemen dat uw bedrijf actief is in de horeca, zult u meer reclame krijgen voor keukenmateriaal, het beheer van cateringruimten, de keukenindeling …, Als u een bedrijf bent dat actief is in het bouwmilieu, zult u eerder reclame ontvangen over de vastgoedsector, bouwmachines en materialen.
                </p>
                <p className="yellow-privacy">
                  Deze profielen zijn niet vooraf bepaald. We kunnen ze aanmaken in functie van de vragen van onze klanten en zullen ze niet per se bijhouden
                </p>




                <h4 className="h2-title yellow-privacy">Kunnen deze profielen een juridische invloed op u hebben of u zwaar beïnvloeden?</h4>
                <p className="yellow-privacy">
                  Neen. We evalueren eenvoudigweg wat we in ons jargon uw “marketingpotentieel” noemen, wat wil zeggen: als uw profiel u een meer geïnteresseerde klant dan gemiddeld maakt voor de producten of diensten van een bedrijf. Dit profiel kan slechts een score zijn (bijvoorbeeld voor een model van wagen “beroepsgebruik”, een transportbedrijf krijgt een hogere score dan een marketingbedrijf)  of een beschrijving van uw marketingprofiel. Het gevolg is bijvoorbeeld dat u waarschijnlijk een andere advertentie ontvangt dan pakweg uw buurman. Dit betekent niet dat die ook niet in staat zal zijn om hetzelfde product of dezelfde dienst te verkrijgen onder dezelfde voorwaarden. Alleen de advertentie-inhoud van zijn brievenbus of mailbox zal anders zijn dan de uwe.
                </p>
                <p className="yellow-privacy">
                  Het kan voorkomen dat de score die is gerelateerd aan uw profiel wordt gecombineerd met andere scores van onze klanten. In dit geval is de uiteindelijke interpretatie van alle scores of profielen de verantwoordelijkheid van onze klanten. Als onze klanten deze gecombineerde profielen zouden gebruiken op een manier die een juridisch effect op u kan hebben of u zwaar zou kunnen beïnvloeden, verbinden zij zich ertoe u te informeren en zich te houden aan de toepasselijke wettelijke bepalingen.
                </p>



                <h4 className="h2-title yellow-privacy">WAAR KOMEN DE STATISTISCHE GEGEVENS VAN ENTER-BUSINESS BELGIUM VANDAAN?</h4>
                <p className="yellow-privacy">
                  Deze gegevens zijn afkomstig van het Nationaal Instituut van Statistieken of een andere instelling die deze gegevens op de markt zou kunnen brengen, wat over het algemeen ‘open data’ wordt genoemd. Dit zijn anonieme gegevens met betrekking tot een buurt of een groep individuen en zijn daarom niet gerelateerd aan een specifieke persoon.
                </p>
                <p className="yellow-privacy">
                  ENTER-BUSINESS kan ook zijn eigen statistische modellen bouwen.
                </p>


                {/* paragraphe four ends */}

                {/* paragraphe five starts */}
                <h2 className="h2-title yellow-privacy">5. Hoe kunnen we uw persoonsgegevens gebruiken?</h2>

                <h4 className="h2-title yellow-privacy">OVERDRACHT VAN UW PERSOONSGEGEVENS</h4>

                <p className="yellow-privacy">
                  We gebruiken uw gegevens om deze door te sturen naar bedrijven die contact met u willen opnemen voor reclamedoeleinden: we selecteren bijvoorbeeld alle mensen die in gemeente X wonen, namens een winkel voor levering van kantoorartikelen die in dezelfde stad is gevestigd en die zijn nieuwe catalogus wil verzenden.    </p>

                <p className="yellow-privacy">
                  We gebruiken ze ook om marketingprofielen te maken voor het selecteren van groepen mensen waarvan we denken dat ze meer geïnteresseerd zijn in het ontvangen van specifieke soorten advertenties: we selecteren bijvoorbeeld mensen in buurten waar het percentage tuinen hoog is voor het account van een online winkel van tuinproducten.    </p>

                <p className="yellow-privacy">
                  Uw gegevens worden ook doorgegeven aan een externe partner, voor gebruik door deze laatste of door haar klanten (afkomstig uit diverse sectoren) om u gepersonaliseerde aanbiedingen te bezorgen - eventueel gebaseerd op uw marketingprofiel - of voor de uitvoering van marktonderzoeken of studies. In deze context kunnen uw gegevens eveneens gebruikt worden om gegevens reeds aanwezig in de database van andere bedrijven te valideren, corrigeren of aan elkaar te koppelen.    </p>


                <h4 className="h2-title yellow-privacy">WAT ZIJN DE VERSCHILLENDE CATEGORIEËN KLANTEN DIE UW GEGEVENS ZOUDEN KUNNEN GEBRUIKEN?</h4>


                <p className="yellow-privacy">
                  Automobielsector: producenten, importeurs en dealers van verschillende merken auto's en leasemaatschappijen    </p>

                <p className="yellow-privacy">
                  Reclamebureaus: agentschappen die reclamecampagnes voorbereiden voor hun klanten (bijvoorbeeld lay-out en verzending van een mailing, e-mailcampagne, ...)
                </p>

                <p className="yellow-privacy">
                  Duurzame goederen: fabrikanten van producten met een lange levensduur, zoals huishoudelijke apparaten (wasmachine, koffiezetapparaat, ...), televisie, meubels en de bouwsector
                </p>
                <p className="yellow-privacy">
                  Consumptiegoederen: producenten van consumentenproducten (voedingsmiddelen, drank, voedsel voor huisdieren, schoonmaakproducten, wasproducten, producten voor persoonlijke verzorging, ...) die voornamelijk in supermarkten worden verkocht    </p>
                <p className="yellow-privacy">
                  Financiële sector - banken en verzekeringen: banken, verzekerings- en kredietinstellingen    </p>
                <p className="yellow-privacy">
                  Fondsenwerving: goede doelen die aan fondsenwerving doen voor goede doelen    </p>
                <p className="yellow-privacy">
                  Gezondheid: bedrijven in de gezondheidssector zoals ziekteverzekeringen, para-farmaceutische producenten, ...    </p>
                <p className="yellow-privacy">
                  Hobby’s en vrije tijd: tentoonstellingen, theater, bioscoop, musea, speelgoed, ...    </p>
                <p className="yellow-privacy">
                  Computer: software- en computerbedrijven    </p>
                <p className="yellow-privacy">
                  Instellingen: beroepsfederaties, consumentenverenigingen, politieke partijen, ...    </p>
                <p className="yellow-privacy">
                  Lijstmakelaar: tussenpersoon voor de commercialisering van gegevens in alle genoemde sectoren    </p>
                <p className="yellow-privacy">
                  Loterijen: loterij, casino    </p>
                <p className="yellow-privacy">
                  Detailhandel: winkelketens, supermarkten, ...    </p>
                <p className="yellow-privacy">
                  Marktonderzoek: instituten voor marktonderzoek    </p>
                <p className="yellow-privacy">
                  Overheidsinstellingen: administratie, transport, distributie van post, ...    </p>
                <p className="yellow-privacy">
                  Postorder: bedrijven die diensten en goederen aanbieden via internet of huis-aan-huis    </p>
                <p className="yellow-privacy">
                  Telecommunicatie: telefonie (mobiel en/of vast), internet- en tv-aanbieders    </p>
                <p className="yellow-privacy">
                  Toerisme: reisbureaus, vakantieverhuur, ...
                </p>
                <p className="yellow-privacy">
                  Media en publishing: kranten, tijdschriften en boekuitgevers
                </p>
                <p className="yellow-privacy">
                  Nutsvoorzieningen: nutsvoorzieningen zoals elektriciteits-, water- en gasleveranciers
                </p>
                <p className="yellow-privacy">
                  Diversen: elk ander bedrijf dat zich inzet voor de consument
                </p>





                <h4 className="h2-title yellow-privacy">
                  GEBRUIK VAN UW PERSOONSGEGEVENS OM DE GEGEVENSKWALITEIT OP DE MARKT TE VERBETEREN
                </h4>

                <p className="yellow-privacy">
                  We gebruiken uw gegevens voor validatiedoeleinden: bedrijven vragen ons om te controleren of de gegevens die ze hebben ook in ons bestand aanwezig zijn. Hierdoor kunnen ze controleren of hun adressen up-to-date of juist zijn (als ze u een pakket moeten sturen, is het beter als het adres correct is gelabeld). In dit concrete geval, verzenden wij uw gegevens niet naar hen, we geven eenvoudig aan of de onderzochte gegevens dezelfde zijn als de onze of niet.
                </p>
                <p className="yellow-privacy">
                  We kunnen ook bedrijfsgegevens corrigeren door deze met de onze te vergelijken. We kunnen dus een postcode corrigeren die niet overeenkomt met de aangegeven straat, we kunnen ook een verhuis communiceren... Nogmaals, hierdoor kunnen bedrijven gegevens up-to-date houden om beter met u te communiceren.
                </p>
                <p className="yellow-privacy">
                  Ten slotte kunnen we bedrijfsgegevens uitbreiden. Als het bedrijf in kwestie bijvoorbeeld uw naam en postadres heeft, kunnen we uw telefoonnummer of uw leeftijd opgeven als deze informatie aanwezig is in onze database. We kunnen ook marketingprofielgegevens verstrekken.
                </p>
                <p className="yellow-privacy">
                  Deze informatie zal het bedrijf helpen om u beter te leren kennen en om hun type klant te bepalen. Op deze manier kan ze haar communicatie aanpassen (bijvoorbeeld door een meer “verbonden” taal te gebruiken als ze met jonge klanten praat).
                </p>


                <h4 className="h2-title yellow-privacy">GEBRUIK VAN UW PERSOONSGEGEVENS VOOR EEN DATAGROEP</h4>

                <p className="yellow-privacy">
                  Het kan gebeuren dat een klant verschillende gegevens over u heeft maar deze niet heeft gelinkt, bijvoorbeeld omdat hij ze niet allemaal tegelijkertijd heeft verzameld. In dit geval kunnen we onze gegevens gebruiken om deze bewerking uit te voeren, bijvoorbeeld als we in ons bestand de volgende gegevens hebben: mevrouw Sofie Vandenbossche, Bosstraat 1000 Boshout, tel: 01.123.45.67, s.vandenbossche@skynet.be en dat we in het dossier van de klant enerzijds mevrouw vandenbossche en haar postadres vinden, en aan de anderzijds sofie.vandenbossche@skynet.be en het telefoonnummer 01.123.45.67, in dat geval zullen we de klant laten weten dat het om dezelfde persoon gaat.
                </p>

                <h4 className="h2-title yellow-privacy">GEBRUIK VAN UW PERSOONSGEGEVENS VOOR GEGEVENSANALYSE</h4>

                <p className="yellow-privacy">
                  We hebben het al gehad over de marketingprofielen die we aanmaken. We kunnen ook de gegevens van onze klanten analyseren met behulp van de profielen die we zelf gebruiken. Hierdoor kunnen klanten het type consument dat geïnteresseerd is in hun producten, beter leren kennen. Zo konden we bepalen dat de lezers van XY magazine vrij jonge mannen zijn, geïnteresseerd in sport en film. Hierdoor kan de klant abonnementen aanbieden met bijvoorbeeld gratis filmtickets. Zijn actie zal ongetwijfeld succesvoller zijn dan wanneer hij make-upproducten aanbiedt.
                </p><p className="yellow-privacy">
                Na het analyseren van de gegevens van de klant, kunnen we hem ook voorstellen dat we uit onze database mensen met hetzelfde profiel selecteren. In ons voorbeeld zal zijn prospectieve reclamecampagne waarschijnlijk meer succes hebben dan wanneer hij zich op vrouwen richt.
              </p><p className="yellow-privacy">
                We kunnen deze selectie doorgeven aan sociale netwerken of andere media. Vervolgens controleren zij of ze u in hun eigen database vinden en geven ze dezelfde advertentie-actie weer op uw pagina.
              </p><p className="yellow-privacy">
                Ten slotte kunnen uw gegevens ook worden gebruikt in de context van analyses van massagegevens, altijd voor het doel van marketing of marktonderzoek.
              </p>

                <h4 className="h2-title yellow-privacy">AANWENDING VAN UW PERSOONSGEGEVENS VOOR INTERN GEBRUIK</h4>
                <p className="yellow-privacy">
                  We kunnen uw gegevens ook verwerken voor intern gebruik in ons bedrijf, bijvoorbeeld:
                </p><p className="yellow-privacy">
                Wij kunnen uw gegevens gebruiken om u op de hoogte te stellen van de beschikbaarheid van uw lidkaart, om u belangrijke informatie met betrekking tot uw bedrijf te bezorgen, om u een promotie te sturen inzake onze andere media, of een enquête te organiseren om de kwaliteit van onze diensten of media te meten.
              </p><p className="yellow-privacy">
                Wij kunnen uw informatie gebruiken om u namens ENTER-BUSINESS informatie door te geven van een derde partij die mogelijk interessant is voor u als jonge ouder.
              </p>

                {/* paragraphe five ends */}


                {/* paragraphe six starts */}
                <h2 className="h2-title yellow-privacy">6. Wat is de wettelijke basis voor het verwerken van uw persoonsgegevens?</h2>

                <p className="yellow-privacy">
                  Op basis van uw toestemming en de wettelijke basis van artikel 6a van de AVG: vooral wanneer u ons vrijwillig gegevens meedeelt op onze sites <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  of een andere vragenlijst (die wij of onze partners kunnen bewerken) of wanneer u rechtstreeks contact met ons opneemt voor een specifiek verzoek of wanneer u vrijwillig toestemming geeft voor de verwerking van uw gegevens door een ander kanaal, zoals een papieren coupon die u hebt ingevuld.
                </p><p className="yellow-privacy">
                Vanzelfsprekend moet deze interesse voortdurend worden afgewogen tegen de rechten van de mensen die in ons bestand zijn geregistreerd. Daarom streven wij ernaar om zo transparant mogelijk te zijn over het gebruik van de gegevens en respecteren we de AVG met betrekking tot mensenrechten op strikte wijze.
              </p><p className="yellow-privacy">
                Deze wettelijke basis wordt gebruikt voor elke gegevensverwerking waarvoor we niet om uw uitdrukkelijke toestemming vragen.
              </p>
                {/* paragraphe six ends */}




                {/* paragraphe seven starts */}
                <h2 className="h2-title yellow-privacy">7. Hoe lang houden we uw gegevens bij?</h2>
                <p className="yellow-privacy">
                  Onze activiteiten draaien om persoonlijke gegevens, waarvan de houdbaarheid lang genoeg moet zijn om de best mogelijke kwaliteit te behouden en zo het risico op fouten te verminderen. Daarom bewaren wij uw gegevens gedurende een periode van 15 jaar vanaf hun laatste registratie, in onze database. Het is mogelijk dat een andere bron ook uw gegevens aan ons doorspeelt. In dat geval begint de tijdperiode opnieuw vanuit deze nieuwe registratie.             </p><p className="yellow-privacy">
                Deze relatief lange tijd wordt ook verklaard door het feit dat we heel veel verwerkingen inzake persoonlijke gegevens uitvoeren. De bedrijven waarvoor we werken, vereisen dat we de traceerbaarheid van deze behandelingen bijhouden.
              </p><p className="yellow-privacy">
                Zoals we al eerder hebben aangegeven, is het vanzelfsprekend dat wanneer u wilt dat we uw gegevens niet meer gebruiken voor reclamedoeleinden (oppositierecht) of u wenst te worden vergeten, u gewoon de procedure dient te volgen die hieronder beschreven staat. In dat geval is de periode van 15 jaar uiteraard niet van toepassing en heeft u de overhand.
              </p>


                {/* paragraphe seven ends */}

                {/* paragraphe eight starts */}
                <h2 className="h2-title yellow-privacy">8. Hoe beschermen we uw gegevens?</h2>
                <p className="yellow-privacy">
                  Eerst en vooral controleren we nauwgezet of de bron van onze zakelijke partners de AVG respecteert, of het nu gaat om het verzamelen van uw gegevens of de kwaliteit van informatie die zij u verstrekken over de verwerking van uw gegevens. In het bijzonder vragen we hen om zo transparant mogelijk te zijn met betrekking tot het verzenden van gegevens naar ENTER-BUSINESS en om deze webpagina's te raadplegen voor meer informatie over onze activiteiten.
                </p><p className="yellow-privacy">
                We hebben procedures geïmplementeerd om u te helpen uw rechten gemakkelijk uit te oefenen. Bent u niet tegen het ontvangen van advertenties, maar  wilt u het liever via ons ontvangen, alleen via een specifiek medium (bijvoorbeeld alleen naar uw postadres, maar niet via de telefoon)? Geen probleem . Het volstaat om ons op de hoogte te stellen en uw keuzes in uw account aan te passen op de site ENTER-BUSINESS.COM.
              </p><p className="yellow-privacy">
                De veiligheid van uw gegevens is voor ons van cruciaal belang. We hechten er veel zorg aan en we voeren regelmatig interne en externe controles uit om ervoor te zorgen dat de instrumenten en procedures die zijn ingevoerd naar behoren werken.
              </p><p className="yellow-privacy">
                We hebben een “functionaris voor gegevensbescherming” aangesteld om ervoor te zorgen dat uw rechten worden gerespecteerd.
              </p><p className="yellow-privacy">
                Tot slot voerden we, in overeenstemming met de AVG, een analyse uit van het effect van gegevensbescherming. Dit document analyseert alle mogelijke risico's van schade aan uw gegevens en alle maatregelen die genomen moeten worden om dit risico zoveel mogelijk te vermijden of te beperken. De aanbevolen maatregelen zijn geïmplementeerd in ons IT-systeem alsook in onze procedures.
              </p><p className="yellow-privacy">
                We doen er alles aan om de hoogste vertrouwelijkheid en veiligheid voor uw gegevens te waarborgen. In deze context nemen we alle noodzakelijke technische en organisatorische maatregelen.
              </p><p className="yellow-privacy">
                Zo zijn ons netwerk, onze infrastructuur en onze informatiesystemen beschermd. Toegang tot uw persoonlijke gegevens is alleen toegestaan aan personen voor wie het noodzakelijk is voor de uitvoering van hun opdrachten. Ze worden aan strikte professionele discretie gehouden en moeten voldoen aan alle technische voorschriften die worden verstrekt om de vertrouwelijkheid van persoonlijke gegevens te waarborgen. We willen voorkomen dat onbevoegde personen uw gegevens openen, verwerken, wijzigen of vernietigen.
              </p><p className="yellow-privacy">
                In het geval van datalekken, die een risico voor uw privacy met zich kunnen meebrengen, wordt u persoonlijk op de hoogte gebracht, evenals de Commissie voor de bescherming van de persoonlijke levenssfeer, in overeenstemming met onze wettelijke verplichtingen. In dit geval zullen we er alles aan doen om deze situatie zo snel mogelijk te stoppen en alle gevolgen te beperken.
              </p>

                <h4 className="h2-title yellow-privacy">KAN ENTER-BUSINESS UW PERSOONSGEGEVENS BUITEN DE EUROPESE UNIE VERZENDEN?</h4>
                <p className="yellow-privacy">
                  Sommige klanten of leveranciers van ENTER-BUSINESS kunnen uw gegevens buiten de Europese Unie verzenden. In dit geval wordt alle verwerking van uw gegevens beschermd in overeenstemming met de wettelijke bepalingen en wij zorgen ervoor dat de beoogde behandelingen een niveau van bescherming van uw gegevens respecteren dat gelijkwaardig is aan de bestaande bescherming in de Europese Unie, met name door de contractuele clausules van de Europese Commissie te ondertekenen.</p>

                {/* paragraphe eight ends */}



                {/* paragraphe nine starts */}
                <h2 className="h2-title yellow-privacy">9. Wat zijn uw privacyrechten?</h2>
                <h4 className="h2-title yellow-privacy">UW RECHT OP RAADPLEGING</h4>

                <p className="yellow-privacy">
                  U hebt het recht om te allen tijde door ENTER-BUSINESS op de hoogte te worden gesteld over het feit of wij uw persoonsgegevens al dan niet verwerken en, als wij deze verwerken, om deze informatie te raadplegen en om aanvullende informatie te ontvangen over:    </p>
                <ul className="yellow-privacy">

                  <li >De doeleinden van de verwerking;
                  </li>
                  <li >Categorieën van persoonsgegevens in kwestie;
                  </li>
                  <li >Ontvangers of categorie vanontvangers (inclusief ontvangers gevestigd in derde landen);
                  </li>
                  <li >Indien mogelijk, de bewaringstermijn of, in geval van onmogelijkheid, de criteria voor het bepalen van deze periode;
                  </li>
                  <li >Het bestaan van uw privacyrechten;
                  </li>
                  <li >Het recht om een klacht in te dienen bij de toezichthoudende autoriteit;
                  </li>
                  <li >Informatie die we hebben over de bron van de gegevens als we persoonlijke gegevens verkrijgen via een derde partij;
                  </li>
                </ul>
                <h4 className="h2-title yellow-privacy">Het bestaan van geautomatiseerde besluitvorming.</h4>

                <p className="yellow-privacy">
                  U hebt ook het recht om een gratis exemplaar van de verwerkte gegevens te verkrijgen in een verstaanbaar formaat. ENTER-BUSINESS kan een redelijke vergoeding vragen om zijn administratieve kosten te dekken voor elk extra exemplaar dat u aanvraagt.
                </p>
                <h4 className="h2-title yellow-privacy">UW RECHT OM PERSOONSGEGEVENS TE RECTIFICEREN</h4>
                <p className="yellow-privacy">
                  U hebt het recht om onvolledige, onjuiste, onaangepaste of verouderde persoonlijke gegevens te corrigeren. Om uw gegevens up-to-date te houden, vragen wij u om ons op de hoogte te stellen van eventuele wijzigingen, zoals een verhuis of de exacte geboortedatum van uw kinderen. Hiervoor kunt u inloggen op uw account op <a href="https://enter-business.com" alt="enter-business" target="_blank"><a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                </a>.

                </p>


                <h4 className="h2-title yellow-privacy">UW RECHT OP VERWIJDERING VAN PERSOONSGEGEVENS (HET ‘RECHT OM VERGETEN TE WORDEN’)</h4>
                <p className="yellow-privacy">
                  U hebt het recht om de beperking van de verwerking van uw persoonlijke gegevens te verkrijgen en in het bijzonder in verband met het gebruik van uw gegevens voor rechtstreekse marketingdoeleinden.
                </p>
                <p className="yellow-privacy">
                  Om dit te doen, kunt u inloggen op uw account op <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                  en uw keuzes aanpassen aan uw voorkeuren.
                </p>



                <h4 className="h2-title yellow-privacy">UW RECHT OP DE PORTABILITEIT VAN UW PERSOONSGEGEVENS (‘DATA PORTABILITY’)</h4>

                <p className="yellow-privacy">
                  U hebt het recht om uw persoonlijke gegevens “terug te krijgen”.
                </p>
                <p className="yellow-privacy"><span className="yellow-privacy">
Twee aspecten houden verband met dit recht:</span></p>
                <p className="yellow-privacy">Vanuit uw account en op <a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>

                </p>
                <p className="yellow-privacy">1.	A.	Kunt u ENTER-BUSINESS vragen om de betreffende persoonlijke gegevens op te halen.</p>
                <p className="yellow-privacy">2.	B.	Kunt u ENTER-BUSINESS vragen om de betreffende persoonlijke gegevens rechtstreeks naar een andere verwerkingsverantwoordelijke te verzenden. U bent ook persoonlijk verantwoordelijk voor de correctheid en veiligheid van het e-mailadres dat u verstrekt voor verzending. ENTER-BUSINESS heeft het recht om te weigeren als de verzending technisch onmogelijk is.
                </p>


                <h4 className="h2-title yellow-privacy">UW RECHT OM U TE VERZETTEN TEGEN DE VERWERKING VAN UW PERSOONSGEGEVENS</h4>
                <p className="yellow-privacy">
                  U hebt het recht om, op basis van uw specifieke situatie, bezwaar aan te tekenen tegen de verwerking van uw persoonlijke gegevens. ENTER-BUSINESS beëindigt de verwerking van uw persoonlijke gegevens, tenzij ENTER-BUSINESS overtuigende en rechtsgeldige gronden voor verwerking kan aantonen die belangrijker zijn dan de uwe, of waar de verwerking van persoonsgegevens betrekking heeft op het vinden, uitoefenen of verdedigen van uw persoonlijke gegevens in de rechtbank (bijvoorbeeld bij de indiening van een verzoek bij een rechtbank).
                </p>

                {/* paragraphe nine ends */}




                {/* paragraphe ten starts */}
                <h2 className="h2-title yellow-privacy">10. Hoe oefent u praktisch gezien uw privacyrechten uit?</h2>
                <p className="yellow-privacy">
                  Hoe kan ik mijn privacy-rechten uitoefenen? Via de website <a href="https://enter-business.com" alt="enter-business" target="_blank"><a href="https://enter-business.com" alt="enter-business" target="_blank">www.enter-business.com</a>
                </a>.

                </p>
                <p className="yellow-privacy">
                  Brengt deze interventie kosten met zich mee? U kunt uw privacyrechten kosteloos uitoefenen, tenzij uw aanvraag kennelijk ongegrond of overdreven is, vooral vanwege het repetitieve karakter ervan. In dit geval hebben we, in overeenstemming met de privacywetgeving, het recht en de keuze om (i) een redelijke vergoeding in rekening te brengen (rekening houdend met de administratieve kosten van het verstrekken van de gevraagde informatie, of mededelingen en de kosten van gevraagde maatregelen) of (ii) weigeren om aan het verzoek te voldoen.
                </p>
                <p className="yellow-privacy">
                  In welke vorm ontvangt u een antwoord? Wanneer u een aanvraag elektronisch indient, wordt de informatie indien mogelijk elektronisch verstrekt, tenzij u om communicatie op via een andere weg vraagt. Wij bieden u in elk geval een beknopt, transparant, begrijpelijk en makkelijk toegankelijk antwoord.
                </p>
                <p className="yellow-privacy">
                  Wanneer ontvang ik een antwoord? We reageren zo spoedig mogelijk op uw verzoek en in elk geval binnen één maand na ontvangst van uw verzoek. Afhankelijk van de complexiteit van de verzoeken en de hoeveelheid verzoeken, kan deze periode indien nodig met twee maanden worden verlengd. In het geval van verlenging van de deadline, informeren wij u in de maand van ontvangst van het verzoek.
                </p>
                <p className="yellow-privacy">
                  Wat als ENTER-BUSINESS niet op mijn verzoek reageert? Wij informeren u telkens in ons antwoord over de mogelijkheid om een klacht in te dienen bij de toezichthoudende autoriteit en om juridische stappen te ondernemen.Que faire si ENTER-BUSINESS ne donne pas suite à ma demande ? Nous vous informons à chaque fois dans notre réponse de la possibilité de déposer une plainte auprès de l'autorité de contrôle et d'introduire un recours en justice.
                </p>

                {/* paragraphe ten ends */}

                {/* paragraphe eleven starts */}
                <h2 className="h2-title yellow-privacy">11. Hoe contacteer ik ENTER-BUSINESS?</h2>
                <p className="yellow-privacy">
                  Als u zich bij ENTER-BUSINESS wilt aansluiten in verband met het huidige beleid, kunt u dit schriftelijk doen bij het hoofdkantoor van het bedrijf: IN TEMPORE bv – Koningin Astridlaan 92 in 1310 La Hulpe of per e-mail naar het volgende adres: privacy@intempore.biz.
                </p><p className="yellow-privacy">
                Voor informatie over dit beleid of klachten over de verwerking van uw persoonlijke gegevens kunt u contact opnemen met de functionaris voor gegevensbescherming van ENTER-BUSINESS via privacy@intempore.biz.
              </p>

                {/* paragraphe eleven ends */}


                {/* paragraphe twelve starts */}
                <h2 className="h2-title yellow-privacy">12. Blijf op de hoogte van aanpassingen aan deze tekst</h2>
                <p className="yellow-privacy">
                  ENTER-BUSINESS kan dit beleid van tijd tot tijd wijzigen, bijvoorbeeld ten gevolge van marktontwikkelingen en nieuwe verwerkingsactiviteiten van ENTER-BUSINESS. We nodigen u daarom uit om steeds de laatste versie van dit beleid op onze website te raadplegen.
                </p><p className="yellow-privacy">
                Uiteraard informeren wij u op voorhand, via onze websites of andere gemeenschappelijke communicatiekanalen, aangaande elke wijziging van de inhoud en vragen wij om uw voorafgaande toestemming voor onze (nieuwe) verwerkingsactiviteiten, indien wettelijk vereist.
              </p>

                {/* paragraphe twelve ends */}



                {/* paragraphe thirteen starts */}
                <h2 className="h2-title yellow-privacy">13. Communicatie met de toezichthoudende autoriteit</h2>
                <p className="yellow-privacy">
                  U kunt klachten in verband met de verwerking van uw persoonsgegevens door ENTER-BUSINESS richten aan de Gegevensbeschermingsautoriteit (GBA) Drukpersstraat 35, 1000 Brussel, 1000 Brussel / +32 (0) 2 274 48 00 /. https://www.gegevensbeschermingsautoriteit.be/burger</p>

                {/* paragraphe thirteen ends */}


              </Col>
              {/*<Col lg="2" className="text-center mt-5">
                <a href={'/feed'} className="btn BackBtn">
                  <img
                    className="img-fluid"
                    src={backBtn}
                    alt="Enter Business"
                  />
                  <span>Back</span>
                </a>
              </Col>*/}
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  )
}


export default PrivacyTerms



